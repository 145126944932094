import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";


const Home = lazy(() => import("../components/routes/Home"));
const Game = lazy(() => import("../components/routes/Game"));

export default function Nav() {
  return (
    <Router>
      <header className="bg-gray-800 md:sticky top-0 z-10">
        <div>
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/Game">Game</Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/Game" component={Game} />
        </Switch>
      </Suspense>
    </Router>
  );
}
